import { Component, Input, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { map,finalize } from 'rxjs/operators';
import { ClientShareModel, DocumentModel } from '../../models/document.model';
import { DocumentService } from '../../service/document/document.service';
import { DocumentDetailComponent } from '../../components/document-detail/document-detail.component';
import { DialogComponent, IMenuItems } from '../../../general-components';
import { slideFromTop } from '../../../general-components';
import {
  ITableOption,
  ITableView,
  TableViewComponent,
} from '../../../general-components/table-view/table-view.component';
import { PermissionService } from '../../../services/permissions/permission.service';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { DocumentViewComponent } from '../../components/document-view/document-view.component';
import { GatekeeperService, PageMenuService, UserInfoService } from '../../../services';
import {
  StatusEnum,
  UserTypeEnum,
} from '../../../models';
import { ReadByModel } from '../../../models/comment/read-by.model';
import { DocumentSignComponent } from '../../components/document-sign/document-sign.component';
import { ClientOverviewService } from '../../../shared-forms/care-program/services/client-overview.service';
import { AUDIT_LOGS_PAGE, DOCUMENT_MODE } from '../../../utilities';
import { AuditLogComponent } from '../../components/audit-log/audit-log.component';
import { ShareAndSignComponent } from '../../components/share-and-sign/share-and-sign.component';
import { OPERATION_TYPES } from '../../const/operation-types.const';
import {
  DocumentUploadSidesheetComponent
} from '../../components/document-upload-sidesheet/document-upload-sidesheet.component';
import { SideSheetConfig, SideSheetContent, SideSheetService, SideSheetSize } from 'tuula-common';

@Component({
  selector: 'app-document-overview',
  templateUrl: './document-overview.component.html',
  styleUrls: ['./document-overview.component.scss'],
  animations: [slideFromTop],
})
export class DocumentOverviewComponent implements OnInit, OnDestroy {
  @Input() applicationViewMode: boolean = false;
  @Input() careprogramViewMode: boolean = false;
  @Input() userPortal: string;
  @Input() operation: string;
  @Input() section: string;
  @Input() services: any[];
  @Input() careprogramId: string;
  @Input() clientId: string;
  tenantId: any;
  isClientPortal: boolean;
  private previousMenuItems: IMenuItems[];
  public tableOptions: ITableOption = null!;
  currentUserId: any;
  currentUserName: string;
  userType: string;
  private routeControl: any;
  allowedExtensionsForEditAndView = ['pdf', 'png', 'jpg', 'jpeg', 'bmp', 'gif'];

  uploadButton = this.sideSheetService.createPrimaryButton(
    'carefile.actions.Upload',
    'upload',
  );

  private showAdd(): boolean {
    if (this.documentService.getCarefileStatus() === StatusEnum.Archived) {
      return this.permissionService.getPermission(
        'carefile:viewarchive',
        'or',
      );
    } else if (this.careprogramViewMode) {
      return this.permissionService.getPermission(
        'careprogram:zpm:carefile:full|careprogram:zpm:full',
        'or',
      );
    } else if (!this.documentService.isCarefile()) {
      return this.permissionService.getPermission('application:full');
    } else if (this.documentService.isEmployeePortal()) {
      return this.permissionService.getPermission(
        'file:carefile:create|file:carefile:manage',
        'or',
      );
    } else if (this.documentService.isClientPortal()) {
      return this.permissionService.getPermission('file:cp:carefile:create');
    }
  }

  private canEditOrDelete(document: DocumentModel): boolean {
    if (this.documentService.getCarefileStatus() === StatusEnum.Archived) {
      return !!document?.isArchived;
    }
    let allowedPermissions = [];
    if (this.careprogramViewMode) {
      allowedPermissions.push('careprogram:zpm:carefile:full');
      allowedPermissions.push('careprogram:zpm:full');
    } else if (this.documentService.isCarefile()) {
      if (
        this.documentService.isClientPortal() &&
        document.createdBy &&
        document.createdBy.id == this.userInfoService.userId
      ) {
        allowedPermissions.push('file:cp:carefile:create');
      } else if (this.documentService.isEmployeePortal()) {
        // Future: Also handle view-application-through-carefile explicitly
        allowedPermissions.push('file:carefile:manage');
        if (
          document.createdBy &&
          document.createdBy.id == this.userInfoService.userId
        ) {
          allowedPermissions.push('file:carefile:create');
        }
      }
    } else {
      allowedPermissions.push('application:carefile:manage');
      allowedPermissions.push('application:full');
    }
    return this.hasPermission(allowedPermissions);
  }

  private canEdit(document: DocumentModel): boolean {
    const fileExtension = document.fileData?.extension;

    const canEdit = this.allowedExtensionsForEditAndView.includes(fileExtension);

    if (!canEdit) {
        return false;
    } else {
      return this.canEditOrDelete(document);
    }
  }

  private canView(document: DocumentModel): boolean {
    let allowedPermissions = [];
    const fileExtension = document.fileData?.extension;
    if (!this.allowedExtensionsForEditAndView.includes(fileExtension)) {
      return false;
    }

    if (this.careprogramViewMode) {
      allowedPermissions.push('careprogram:zpm:carefile:full');
      allowedPermissions.push('careprogram:zpm:full');
    } else if (this.documentService.isCarefile()) {
      if (this.documentService.isClientPortal()) {
        allowedPermissions.push('file:cp:carefile:view');
        if (document.createdBy && document.createdBy.id == this.userInfoService.userId) {
          allowedPermissions.push('file:cp:carefile:create');
        }
      } else if (this.documentService.isEmployeePortal()) {
        allowedPermissions.push('file:carefile:view');
        if (document.createdBy && document.createdBy.id == this.userInfoService.userId) {
          allowedPermissions.push('file:carefile:create');
        }
      }
    } else {
      allowedPermissions.push('application:carefile:view');
      allowedPermissions.push('application:carefile:manage');
      allowedPermissions.push('application:full');
      allowedPermissions.push('application:view');
    }

    return this.hasPermission(allowedPermissions);
  }


  private canDownload(document: DocumentModel): boolean {
    if(this.loading()) return false;
    let allowedPermissions = [];
    if (this.careprogramViewMode) {
      allowedPermissions.push('careprogram:zpm:carefile:full');
      allowedPermissions.push('careprogram:zpm:full');
    } else if (this.documentService.isCarefile()) {
      if (this.documentService.isClientPortal()) {
        allowedPermissions.push('file:cp:carefile:download');
        if (
          document.createdBy &&
          document.createdBy.id == this.userInfoService.userId
        ) {
          allowedPermissions.push('file:cp:carefile:create');
        }
      } else if (this.documentService.isEmployeePortal()) {
        // Future: Also handle view-application-through-carefile explicitly
        allowedPermissions.push('file:carefile:download');
        if (
          document.createdBy &&
          document.createdBy.id == this.userInfoService.userId
        ) {
          allowedPermissions.push('file:carefile:create');
        }
      }
    } else {
      allowedPermissions.push('application:carefile:manage');
      allowedPermissions.push('application:full');
      allowedPermissions.push('application:view');
    }
    return this.hasPermission(allowedPermissions);
  }

  private hasPermission(allowedPermissions: string[]) {
    return this.permissionService.getPermission(
      allowedPermissions.join('|'),
      'or',
    );
  }

  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 20,
    sortColumn: 'createdAt',
    sortDirection: 'desc',
    search: '',
  };

  menuActions: IMenuItems[] = [
    {
      icon: 'filter_list',
      tooltip: 'general.actions.ToggleFilters',
      method: () => (this.toggleFilters = !this.toggleFilters),
    },
  ];

  @ViewChild(TableViewComponent) tableViewComponent: TableViewComponent;
  private _toggleFilters = false;
  private currentUserSigned = false;
  private currentUserSignatureRequired = false;
  private loadingDialogRef;
  loading = signal(false);
  public get toggleFilters() {
    return this._toggleFilters;
  }

  public set toggleFilters(toggled: boolean) {
    // reset tableview
    this.tableView.pageIndex = 0;
    this.tableView.search = null;
    // reset params
    this._filterParams = {};
    // reset table component
    this.tableViewComponent.pageIndex = 0;

    this._toggleFilters = toggled;
    this.refresh();
  }

  private _filterParams = {};
  public get filterParams() {
    return this._filterParams;
  }

  public set filterParams(filterParams: any | null) {
    this._filterParams = filterParams;
    this.tableViewComponent.pageIndex = 0;
    this.tableView.pageIndex = 0;
  }

  private applicationId: string;

  constructor(
    private documentService: DocumentService,
    private permissionService: PermissionService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private pageMenuService: PageMenuService,
    private userInfoService: UserInfoService,
    public clientService: ClientOverviewService,
    private route: Router,
    private gatekeeperService: GatekeeperService,
    private sideSheetService: SideSheetService,
    private matDialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.isClientPortal = this.documentService.isClientPortal();
    this.tableOptions = this.buildTable();
    this.documentService.documents$.next({ data: [], count: 0 });
    this.setMenuItems();
    this.refresh();
    this.applicationId = this.filterParams.applicationId;
    this.tableOptions.showAdd = this.showAdd() && !this.applicationViewMode;
    this.userType = this.userInfoService.userType;
    this.userInfoService.userInfo$.subscribe((info) => {
      if (info && info.id) {
        this.currentUserId = info.id;
        this.currentUserName = info.fullName;
      }
    });
  }

  public refresh(): void {
    let newFilterParams = { ...this.filterParams };
    newFilterParams.userId = this.userInfoService.userId;
    if (newFilterParams.userId) {
      if (newFilterParams.services) {
        newFilterParams.serviceFilterIds = newFilterParams.services.map(
          (service) =>
            this.documentService.isCarefile()
              ? service.id
              : service.globalServiceId,
        );
        delete newFilterParams.services;
      }
      if (newFilterParams.categories) {
        newFilterParams.categories = this.filterParams.categories.map(
          (category) => category.name,
        );
      }
      this.documentService
        .getDocuments(
          PaginationDataModel.fromTableView(this.tableView),
          newFilterParams,
        )
        .subscribe((result) => {
          this.documentService.documents$.next({
            data: result.docs,
            count: result.totalDocs,
          });
        });
    }
  }

  public create(): void {
    const config = new SideSheetConfig();
    const content = new SideSheetContent();

    config.size = SideSheetSize.SM;

    content.title = 'carefile.actions.FilesUploader';
    content.data = {
      applicationId: this.documentService.documentParams$.value.applicationId,
      careprogramViewMode: this.careprogramViewMode,
      carefileId: this.documentService.documentParams$.value.carefileId,
      readBy: this.prepareReadBy(),
      services: this.services,
      careprogramId: this.careprogramId,
      clientId: this.clientId,
    };
    content.buttons = [ this.sideSheetService.createCancelButton(), this.uploadButton];

    if (!this.services || this.services?.length === 0) {
      this.toastr.error(this.translate.instant('document.messages.noServices'));
      return;
    }

    const sideSheetRef = this.sideSheetService.loadComponent(DocumentUploadSidesheetComponent, content, config);

    sideSheetRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  public edit(document: DocumentModel): void {
    if (document.status.toString() == StatusEnum.Closed) {
      this.toastr.error(
        this.translate.instant('document.messages.EditClosedDocument'),
      );
    } else if (!this.services || this.services?.length === 0) {
      this.toastr.error(this.translate.instant('document.messages.noServices'));
      return;
    } else {
      const dialogRef = this.dialog.open(DocumentDetailComponent, {
        data: { document, applicationId: this.applicationId },
      });
      dialogRef.afterClosed().subscribe((modalDocument) => {
        if (modalDocument) {
          this.documentService
            .updateDocument(modalDocument)
            .subscribe((createdDocument) => {
              this.toastr.success(
                this.translate.instant('general.labels.Success'),
              );
              this.refresh();
            });
        }
      });
    }
  }

  public view(document: DocumentModel): void {
    this.documentService
      .getDocumentUrl(document.id, DOCUMENT_MODE.VIEW)
      .subscribe((res) => {
        const blob = new Blob([res], { type: res.type });
        const documentUrl = URL.createObjectURL(blob);
        const dialogRef = this.dialog.open(DocumentViewComponent, {
          data: { documentUrl, document },
        });
      });
  }

  public download(document: DocumentModel): void {
    this.loading.set(true)
    const fileName = `${document.documentName}`;
    this.documentService
      .getDocumentUrl(document.id, DOCUMENT_MODE.DOWNLOAD)
      .pipe(
        finalize(() => {
          this.loading.set(false)
        })
      )
      .subscribe((documentUrl) => {
        const blob = new Blob([documentUrl], { type: documentUrl.type });
        saveAs(blob, fileName)
      });
  }

  public delete(document: DocumentModel): void {
    if (document?.status?.toString() === StatusEnum.Closed) {
      this.toastr.error(
        this.translate.instant('document.messages.DeleteClosedDocument')
      );
    } else {
      const additionalOriginPath = document?.carefileId
        ? '/carefile/:carefile_id'
        : '';
      const method = 'DELETE';
      const endpoint = `/documents${additionalOriginPath}/:document_id`;
      const documentName = document?.documentName;
      const message = this.translate.instant('general.labels.AreYouSureDeleteFile', { fileName: documentName });

      const config = this.gatekeeperService.requiresValidation(method, endpoint);

      const shouldAskManualConfirmation = !(config?.requireConfirmation || config?.requireReason);

      if (shouldAskManualConfirmation) {
        this.confirmDelete(message, document?.id);
      } else {
        this.deleteDocument(document?.id);
      }
    }
  }

  private confirmDelete(message: string, documentId?: string): void {
    this.dialog.open(DialogComponent, {
      data: {
        title: this.translate.instant('general.messages.DeleteItem'),
        message: message,
        confirmText: this.translate.instant('general.actions.delete'),
        requireConfirmationCheckbox: true,
        checkboxStatus: this.translate.instant('general.status.Yes'),
        method: 'DELETE',
      },
      panelClass: 'dialog-delete',
    }).afterClosed().subscribe(
      (confirmed) => {
        if (confirmed) {
          this.deleteDocument(documentId);
        }
      }
    );
  }

  private deleteDocument(documentId?: string): void {
    this.documentService.deleteDocument(documentId).subscribe(
      () => {
        this.toastr.success(
          this.translate.instant('document.crud.Deleted'),
          'Success',
        );
        this.refresh();
      },
      (error) => {
        this.toastr.error(
          this.translate.instant('document.crud.DeleteFailed'),
          'Error'
        );
      }
    );
  }

  public auditLog(document: DocumentModel): void {
    const dialogRef = this.dialog.open(AuditLogComponent, {
      data: { document },
    });
    dialogRef.afterClosed().subscribe((updatedDocument: DocumentModel) => {
      if (updatedDocument) {
        this.documentService.updateSharing(updatedDocument).subscribe(() => {
          this.toastr.success(this.translate.instant('general.labels.Success'));
          this.refresh();
        });
      }
    });
  }

  public shareAndSign(document: DocumentModel): void {

    if (this.userPortal != 'employee' || !this.isSignatureBadgeActive(document)) return;
    const dialogRef = this.dialog.open(ShareAndSignComponent, {
      data: { document: document, section: this.section },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  public share(document: DocumentModel): void {
    if (this.userPortal != 'employee') return;
    const dialogRef = this.dialog.open(ShareAndSignComponent, {
      data: { document: document, section: this.section },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  private setMenuItems(): void {
    if (
      !this.documentService.isCarefile() ||
      this.documentService.section() === 'careprogram'
    ) {
      this.previousMenuItems = this.pageMenuService.menuItems$.value;
      if (!this.previousMenuItems) {
        this.previousMenuItems = [];
      }
      this.pageMenuService.setMenuItems([
        ...this.previousMenuItems,
        ...this.menuActions,
      ]);
    }
    this.pageMenuService.setMenuItems(this.menuActions);
  }

  public sign(document: DocumentModel): void {
    const dialogRef = this.dialog.open(DocumentSignComponent, {
      data: {
        document,
        userPortal: this.section,
        operation: OPERATION_TYPES.APPLICATION_APPROVAL,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    if (
      !this.documentService.isCarefile() ||
      this.documentService.section() === 'careprogram'
    ) {
      this.pageMenuService.setMenuItems(this.previousMenuItems);
    }
  }

  isSignatureColumnPermission(permission: string): boolean {
    return this.permissionService.getPermission(
      permission,
      'or',
    );
  }


  prepareReadBy() {
    const clientReadBy = [];
    const clientNetworkReadBy = [];
    const readBy = new ReadByModel();
    readBy.tenantId = this.tenantId;
    readBy.entityId = null;
    readBy.entity = 'document';
    readBy.user = {
      id: this.currentUserId,
      fullName: this.currentUserName,
      userType: this.userType,
    };
    if (this.userType === UserTypeEnum.Client) {
      clientReadBy.push(readBy);
    } else if (this.userType === UserTypeEnum.ClientNetwork) {
      clientNetworkReadBy.push(readBy);
    }
    return {
      clients: clientReadBy,
      employees: [],
      clientNetworks: clientNetworkReadBy,
    };
  }

  getBadge(sharing, signatureLogs) {
    this.currentUserSigned = signatureLogs?.some(log => log?.signedBy?.id === this.currentUserId);
    const currentUserSignatureRequested = sharing.some(shr => shr?.userId === this.currentUserId && shr?.signatureRequired);
    let badgeResult = new BadgeModel();
    if (this.currentUserSigned) {
      badgeResult.mySignatureToolTip = this.translate.instant('document.labels.MySignatureSigned');
      badgeResult.mySignatureColor = 'Green';
    } else {
      currentUserSignatureRequested ? badgeResult.mySignatureToolTip =
        this.translate.instant('document.labels.MySignatureNotSigned') : this.translate.instant('document.labels.MySignatureNotRequested');
      badgeResult.mySignatureColor = 'Gray';
    }
    if (this.documentService.isClientPortal()) {
      badgeResult.onlyMySignature = true;
      this.checkPendingSignatureForClientNetwork(sharing, signatureLogs, badgeResult);
      return badgeResult;
    }
    const internalSharedList = sharing.filter(sharedPerson => sharedPerson.sharingType === 'internal');
    const externalSharedList = sharing.filter(sharedPerson => sharedPerson.sharingType === 'external');
    this.setInternalValues(internalSharedList, externalSharedList, signatureLogs, badgeResult);
    this.setExternalValues(externalSharedList, signatureLogs, badgeResult);
    this.checkPendingSignatures(sharing, signatureLogs, badgeResult);
   if(badgeResult.internalSignRatio === 0 && badgeResult.externalSignCount ===0 && badgeResult.mySignatureColor === 'Green'){
      const changedBadgeResult = new BadgeModel();
      changedBadgeResult.mySignatureColor = badgeResult.mySignatureColor;
      changedBadgeResult.mySignatureToolTip = badgeResult.mySignatureToolTip;
      changedBadgeResult.onlyMySignature = badgeResult.onlyMySignature;
      badgeResult = changedBadgeResult;
    }
    return badgeResult;
  }

  private setExternalValues(externalSharedList, signatureLogs, badgeResult: BadgeModel) {
    let externalSuccessSignatures = 0;
    let extraSignCount = 0;
    let externalColor = 'Gray';

    externalSharedList?.forEach((sharedPerson) => {
      if (
        signatureLogs?.some(
          (log) => log.signedBy?.id === sharedPerson?.userId,
        ) &&
        sharedPerson?.signatureRequired
      ) {
        externalSuccessSignatures++;
      }
    });

    signatureLogs?.forEach((log) => {
      if (
        externalSharedList?.find(
          (shr) =>
            shr.userId === log?.signedBy?.id && !shr?.signatureRequired,
        )
      ) {
        extraSignCount++;
      }
    });
    if (
      this.checkAllSigned(externalSharedList, signatureLogs) &&
      externalSharedList?.some((sharedPerson) => sharedPerson?.signatureRequired)
    ) {
      externalColor = 'Green';
    }
    const requiredSignatures =
      externalSharedList?.filter(
        (sharedPerson) => sharedPerson?.signatureRequired,
      )?.length ?? 0;

    if (requiredSignatures === 0 && extraSignCount > 0) externalColor = 'Green';

    badgeResult.externalRatio = `${
      externalSuccessSignatures + extraSignCount
    }/${requiredSignatures + extraSignCount}`;
    badgeResult.externalColor = externalColor;
    badgeResult.externalSignCount = requiredSignatures + extraSignCount;
    badgeResult.externalTooltip = `
    ${this.translate.instant('document.labels.ClientSignatures')}
    ${externalSuccessSignatures} ${this.translate.instant(
      'document.labels.Signed',
    )}, ${requiredSignatures} ${this.translate.instant(
      'document.labels.Requested',
    )}`;
  }

  checkPendingSignatures(shareList, logs, badgeResult) {
    const pendingSignatures = shareList?.filter(shr => shr.signatureRequired && !logs?.some(log => log.signedBy.id === shr.userId));

    if (pendingSignatures?.some(shr => shr.sharingType === 'internal')) {
      badgeResult.internalColor = 'Yellow';
    }
    if (pendingSignatures?.some(sharedPerson => sharedPerson.sharingType === 'external')) {
      badgeResult.externalColor = 'Yellow';
    }

    if (pendingSignatures.some(sharedPerson => sharedPerson.userId === this.currentUserId)) {
      badgeResult.mySignatureColor = 'Yellow';
      badgeResult.mySignatureToolTip = badgeResult.mySignatureToolTip = this.translate.instant('document.labels.MySignaturePending');
    }
  }

  checkPendingSignatureForClientNetwork(shareList, logs, badgeResult) {
    const pendingSignatures = shareList?.filter(shr => shr.signatureRequired && !logs?.some(log => log.signedBy.id === shr.userId));
    if (pendingSignatures.some(sharedPerson => sharedPerson.userId === this.currentUserId)) {
      badgeResult.mySignatureColor = 'Yellow';
      badgeResult.mySignatureToolTip = badgeResult.mySignatureToolTip = this.translate.instant('document.labels.MySignaturePending');
    }
  }

  checkAllSigned(shareList, logs) {
    if (!shareList?.some(shr => shr?.signatureRequired)) return true;
    shareList?.forEach(sharedPerson => {
      if (!logs?.some(log => log?.signedBy?.id === sharedPerson?.userId)) {
        return false;
      }
    });
    return true;
  }

  private setInternalValues(internalSharedList, externalShareList, signatureLogs, badgeResult: BadgeModel) {
    let internalSuccessSignatures = 0;
    let internalColor = 'Gray';
    let extraSignCount = 0;

    internalSharedList?.forEach(sharedPerson => {
      if (signatureLogs?.some(log => log?.signedBy?.id === sharedPerson?.userId) && sharedPerson?.signatureRequired) {
        internalSuccessSignatures++;
      }
    });
    signatureLogs?.forEach(log => {
      if (!(externalShareList?.length > 0) || !externalShareList?.some(shr => shr?.userId === log?.signedBy?.id)) {
        if (internalSharedList?.length === 0 || (!internalSharedList?.some(shr => shr?.userId === log?.signedBy?.id)) || (internalSharedList?.find(shr => (shr?.userId === log?.signedBy?.id) && !shr?.signatureRequired))) {
          extraSignCount++;
        }
      }
    });
    if (this.checkAllSigned(internalSharedList, signatureLogs) && internalSharedList?.some(shr => shr?.signatureRequired)) internalColor = 'Green';
    const requiredSignatures = internalSharedList?.filter(sharedPerson => sharedPerson?.signatureRequired)?.length ?? 0;

    if (requiredSignatures === 0 && extraSignCount > 0) internalColor = 'Green';
    badgeResult.internalRatio = `${internalSuccessSignatures + extraSignCount}/${requiredSignatures + extraSignCount}`;
    badgeResult.internalColor = internalColor;
    badgeResult.internalSignRatio = requiredSignatures / extraSignCount;
    badgeResult.internalTooltip = `
    ${this.translate.instant('document.labels.EmployeeSignatures')}
    ${internalSuccessSignatures + extraSignCount} ${this.translate.instant('document.labels.Signed')}, ${requiredSignatures} ${this.translate.instant('document.labels.Requested')}`;
    return internalColor;
  }

  buildTable(): ITableOption {
    return {
      name: 'document.labels.Files',
      titleIcon: 'file_copy',
      dataObservable: this.documentService.documents$.pipe(
        map((documentPagination) => {
          if (documentPagination) {
            const documents = documentPagination.data;
            documents.map((document: any) => {
              document.documentName = document.fileData?.fileName;
              document.documentType = document.fileData?.extension;
              document.documentSize = document.fileData?.fileSize;
              document.createdByFullName = document.createdBy.fullName;
              document.signedByCurrentUser = false;

              document?.signatureLogs?.map((log) => {
                if (log.signedBy?.id === this.currentUserId)
                  document.signedByCurrentUser = true;
              });
              document.sharing.map((share) => {
                share.name = share?.fullName;
                if (share.userId === this.currentUserId)
                  document.signatureRequired = share.signatureRequired;
              });
              document['documentBadge'] = this.getBadge(
                document.sharing,
                document.signatureLogs,
              );
            });
            return documentPagination;
          }
        }),
      ),
      columns: [
        {
          id: 'documentBadge',
          name: 'document.labels.Signature',
          type: 'object',
          format: 'badge',
          width: 100,
          objectProperty: 'value',
         hideCondition: (data) =>
            ((!data?.sharing || !data?.sharing?.some(item => item?.signatureRequired === true)) && (!data?.signedByCurrentUser)) || !this.pdfDocument(data),
          method: data => this.shareAndSign(data),

          hideColumn: !this.isSignatureColumnPermission('carefile:files:digitalSignature:view'),
        },
        {
          id: 'documentName',
          name: 'document.labels.DocumentName',
          type: 'string',
          format: '',
          width: 120,
        },
        {
          id: 'description',
          name: 'document.labels.Description',
          type: 'string',
          format: '',
          width: 100,
        },
        {
          id: 'services',
          name: 'document.labels.Service',
          type: 'objectList',
          format: '',
          position: 'second',
        },
        {
          id: 'category',
          name: 'document.labels.Category',
          type: 'object',
          objectProperty: 'name',
          width: 80,
        },
        {
          id: 'documentType',
          name: 'document.labels.DocumentType',
          type: 'string',
          format: '',
          width: 50,
        },
        {
          id: 'documentSize',
          name: 'document.labels.DocumentSize',
          type: 'string',
          format: '',
          width: 80,
        },
        {
          id: 'createdByFullName',
          name: 'document.labels.User',
          type: 'string',
          format: '',
          width: 120,
        },
        {
          id: 'createdAt',
          name: 'document.labels.Date',
          type: 'date',
          format: 'tenantDate',
          width: 80,
        },
        {
          id: 'sharing',
          name: 'document.labels.sharedWith',
          type: 'objectList',
          format: '',
          position: 'second',
          width: 80,
        },
      ],
      inlineActions: [],
      menuActions: [
        {
          icon: 'create',
          tooltip: 'general.actions.Edit',
          method: (data) => this.edit(data),
          conditionMethod: (data) =>
            this.canEdit(data) &&
            data.status !== StatusEnum.Closed &&
            !this.applicationViewMode,
        },
        {
          icon: 'visibility',
          tooltip: 'general.actions.View',
          method: (data) => this.view(data),
          conditionMethod: (data) => this.canView(data),
        },
        {
          icon: 'file_download',
          tooltip: 'general.actions.Download',
          method: (data) => this.download(data),
          conditionMethod: (data) => this.canDownload(data),
        },
        {
          icon: 'menu',
          tooltip: 'document.labels.share',
          visibleConditionMethod: (data) => this.isShareVisible(data),
          method: (data) => this.share(data),
        },
        {
          icon: 'menu',
          tooltip: 'document.labels.shareAndSign',
          visibleConditionMethod: (data) => this.isShareAndSignVisible(data),
          method: (data) => this.shareAndSign(data),
        },

        {
          icon: 'history',
          tooltip: 'general.actions.AuditLog',
          method: (data) => this.auditLog(data),
          conditionMethod: (data) =>
            data.status === StatusEnum.Active &&
            !this.applicationViewMode &&
            this.hasAuditLogPermission(),
        },
        {
          icon: 'delete',
          tooltip: 'general.actions.Delete',
          method: (data) => this.delete(data),
          conditionMethod: (data) =>
            this.canEditOrDelete(data) &&
            data.status !== StatusEnum.Closed &&
            !this.applicationViewMode,
        },


        {
          icon: 'menu',
          tooltip: 'general.actions.Sign',
          visibleConditionMethod: (document) => this.checkSignVisible(document),
          method: (data) => this.sign(data),
        },
      ],
      showHeader: true,
      showSearch: true,
      showPagination: true,
      externPagination: true,
      defaultPageSize: 20,
      defaultSortColumn: 'createdAt',
      defaultSortDirection: 'desc',
    };
  }

  checkSignVisible(document) {
    return document.sharing.some((shr: ClientShareModel) => shr.userId === this.currentUserId && shr?.signatureRequired)
      && this.userPortal === 'client' && this.permissionService.getPermission('carefile:files:digitalSignature:create', 'or');
  }

  hasAuditLogPermission() {
    if (this.getCurrentPage() === AUDIT_LOGS_PAGE.APPLICATION) {
      return this.permissionService.getPermission(
        'application:files:audits',
        'or',
      );
    } else if (this.getCurrentPage() === AUDIT_LOGS_PAGE.CAREFILES) {
      return this.permissionService.getPermission(
        'carefile:files:audits',
        'or',
      );
    } else {
      return false;
    }
  }

  getCurrentPage() {
    this.routeControl = this.route.url;
    return this.routeControl.split('/')[1];
  }

  private isShareAndSignVisible(document: DocumentModel): boolean {
    if (!this.pdfDocument(document) || this.userPortal === 'client') return false;

    if (document?.createdBy?.id === this.currentUserId) {
      this.currentUserSignatureRequired = true;
    } else if (document?.sharing && document.sharing?.[0]) {
      this.currentUserSignatureRequired = document.sharing.some((sharedDoc: ClientShareModel) => sharedDoc?.userId === this.currentUserId && sharedDoc?.signatureRequired);
      if (!this.currentUserSignatureRequired) return false;
    } else {
      this.currentUserSignatureRequired = false;
    }

    return (this.currentUserSignatureRequired && this.permissionService.getPermission('carefile:files:digitalSignature:create', 'or'))
      || this.permissionService.getPermission('carefile:files:digitalSignature:view', 'or');
  }

  private pdfDocument(document: DocumentModel): boolean {
    return document.fileData.extension === 'pdf';
  }

  private isShareVisible(document: DocumentModel): boolean {
    return (!this.isShareAndSignVisible(document) && this.isSignatureColumnPermission('carefile:files:share')) && this.userPortal !== 'client';
  }

  private isSignatureBadgeActive(data): boolean {
    if (!this.pdfDocument(data)) return false;
    return this.isShareVisible(data) || this.isShareAndSignVisible(data);
  }
}

class BadgeModel {
  mySignatureToolTip: string;
  mySignatureColor: string;
  internalRatio?: string;
  internalSignRatio?: number;
  internalColor?: string;
  internalTooltip?: string;
  externalRatio?: string;
  externalTooltip?: string;
  externalSignCount?: number;
  externalColor?: string;
  onlyMySignature: boolean;
}
