import { Injectable } from '@angular/core';
import {
  DataWrapper,
  GatekeeperComponent,
  GatekeeperService,
  PermissionService,
} from 'c4p-portal-util';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { last, map, switchMap } from 'rxjs/operators';
import { CarefileService } from '../../services/carefile/carefile.service';

interface EscalatedCarefileAccess {
  carefileId: string;
  expiry: number;
}

interface TeamGatekeeperPeriod {
  carefileId: string;
  expiry: number;
}

export let accessType = '';
export let isCarefileActivated = false;

@Injectable({
  providedIn: 'root',
})
export class CarefileGuard implements CanActivate, CanDeactivate<any> {
  private static readonly EXPIRATION_TIME = 8 * 60 * 60 * 1000;

  private static readonly ESCALATED_ACCESS_KEY = 'escalatedCarefileAccess';

  private static readonly TEAM_GATEKEEPER_PERIOD = 'teamGatekeeperPeriod';

  private get escalatedAccess(): EscalatedCarefileAccess[] {
    return (
      JSON.parse(localStorage.getItem(CarefileGuard.ESCALATED_ACCESS_KEY)) || []
    );
  }

  private set escalatedAccess(escalatedAccess: EscalatedCarefileAccess[]) {
    localStorage.setItem(
      CarefileGuard.ESCALATED_ACCESS_KEY,
      JSON.stringify(escalatedAccess),
    );
  }

  private get teamGatekeeperPeriod(): TeamGatekeeperPeriod[] {
    return (
      JSON.parse(localStorage.getItem(CarefileGuard.TEAM_GATEKEEPER_PERIOD)) ||
      []
    );
  }

  private set teamGatekeeperPeriod(teamAccessPeriod: TeamGatekeeperPeriod[]) {
    localStorage.setItem(
      CarefileGuard.TEAM_GATEKEEPER_PERIOD,
      JSON.stringify(teamAccessPeriod),
    );
  }

  constructor(
    private permissionService: PermissionService,
    private gatekeeperService: GatekeeperService,
    private carefileService: CarefileService,
    public dialog: MatDialog,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Promise<boolean> | Observable<boolean> {
    isCarefileActivated = true;
    const carefileId = this.getCarefileId(route, state);

    return this.carefileService.getCarefile(carefileId).pipe(
      last(),
      switchMap((carefile) => {
        accessType = carefile.access;
        return this.handleCarefileGatekeeper(carefileId, accessType, state);
      }),
    );
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    isCarefileActivated = false;
    accessType = '';
    return true;
  }

  // TODO: come up with a better solution
  private getCarefileId(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): string {
    let carefileId = route.params.carefile_id;
    if (!carefileId) carefileId = route.parent.params.carefile_id;
    if (!carefileId) carefileId = this.carefileService.carefileId;
    if (!carefileId)
      carefileId = state.url.split('/carefiles/')[1].split('/')[0];
    if (!carefileId)
      throw Error('Missing a carefileId in the guard, report to Strahinja 🍔');
    return carefileId;
  }

  public setAccessType(access: string) {
    accessType = access;
  }

  private handleCarefileGatekeeper(
    carefileId: string,
    accessType: string,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const hasCarefileGlobalPermission =
      this.permissionService.getPermission('carefile:global');
    const carefileHasGatekeeper: boolean =
      hasCarefileGlobalPermission || accessType === 'team';
    const teamAccess = this.teamGatekeeperAccessPeriodCheck(carefileId);
    if (carefileHasGatekeeper) {
      const carefileAccess = this.getEscalatedAccess(carefileId);
      if (carefileAccess || accessType === 'individual' || !teamAccess) {
        return of(true);
      } else {
        const config = this.gatekeeperService.requiresValidation(
          'GET',
          '/carefiles/:carefile_id',
        );

        return this.dialog
          .open(GatekeeperComponent, {
            data: config,
            panelClass: 'gk-dialog-container'
          })
          .afterClosed()
          .pipe(
            map((carefileDataWrapper: DataWrapper) => {
              if (carefileDataWrapper) {
                this.setTeamAccessPeriod(carefileId);
                state.root.data = { ...state.root.data, carefileDataWrapper };
                return true;
              } else {
                return false;
              }
            }),
          );
      }
    }
    return of(true);
  }

  private teamGatekeeperAccessPeriodCheck(carefileId: string): boolean {
    const gatekeeper: TeamGatekeeperPeriod[] = this.teamGatekeeperPeriod;
    if (gatekeeper?.length === 0) {
      return true;
    }

    const lastPeriod = gatekeeper.filter(
      (gka) => gka.carefileId === carefileId,
    )[0];
    if (!lastPeriod) {
      return true;
    }

    if (new Date().getTime() > lastPeriod.expiry) {
      this.teamGatekeeperPeriod = gatekeeper.filter(
        (gka) => gka.carefileId !== carefileId,
      );
      return true;
    }

    return false;
  }

  private setTeamAccessPeriod(carefileId: string) {
    const gatekeeper = this.teamGatekeeperPeriod;

    gatekeeper.push({
      carefileId,
      expiry: new Date().getTime() + CarefileGuard.EXPIRATION_TIME,
    });

    this.teamGatekeeperPeriod = gatekeeper;
  }

  public getEscalatedAccess(
    carefileId: string,
  ): EscalatedCarefileAccess | null {
    const globalCarefileAccesses: EscalatedCarefileAccess[] =
      this.escalatedAccess;
    if (globalCarefileAccesses.length === 0) return null;

    const carefileAccess = globalCarefileAccesses.filter(
      (gca) => gca.carefileId === carefileId,
    )[0];
    if (!carefileAccess) return null;

    // compare the expiry time of the item with the current time
    if (new Date().getTime() > carefileAccess.expiry) {
      // If the item is expired, delete the item from storage and return null
      this.escalatedAccess = globalCarefileAccesses.filter(
        (gca) => gca.carefileId !== carefileId,
      );

      return null;
    }

    return carefileAccess;
  }

  public setEscalatedAccess(carefileId: string): void {
    const escalatedCarefileAccesses: EscalatedCarefileAccess[] =
      this.escalatedAccess;
    escalatedCarefileAccesses.push({
      carefileId,
      expiry: new Date().getTime() + CarefileGuard.EXPIRATION_TIME,
    });

    this.escalatedAccess = escalatedCarefileAccesses;
  }
}
