import { SelectionModel } from '@angular/cdk/collections';
import { registerLocaleData, TitleCasePipe } from '@angular/common';
import nl from '@angular/common/locales/nl';
import { OnChanges, SimpleChanges } from '@angular/core';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  pairwise,
  take,
  takeUntil,
} from 'rxjs/operators';
import {
  DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT,
  DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT,
  IFormDropdownOption,
  DATE_REGEX,
} from '../../form-controls/form-controls.const';
import { PaginationDataModel } from '../../models/pagination';
import { ExcelService } from '../../services';
import { PermissionService } from '../../services/permissions/permission.service';
import { TableViewInterface } from './table-view.interface';
import { HOUR_LINK_FORMAT, REGEX_CONSTANTS, SELECT_ALL_KEY } from '../../utilities';
import { TranslateService } from '@ngx-translate/core';

export interface ITableView {
  // Page number in case of pagination
  pageIndex: number;

  // Number of rows per page
  pageSize: number;

  // Column used for sorting
  sortColumn: string;

  // Sorting direction asc or desc
  sortDirection: string;

  // Search keyword
  search: string;

  // TODO changed from any[] to any
  // Additional filters
  additionalFilters?: any;
}

interface ITableColumn {
  // ID should match to JSON key value
  id: string;

  // Display name for the column in a table
  name: string;

  // Following types are supported: date, string, list, number, status, boolean, checkbox, avatar, icon, dateInput, numberInput
  type: string;

  // Depends on type
  format?: string;

  checkboxValue?: boolean;

  // Used for lookup type
  lookupValues?: string[];

  // for format === 'object'
  objectProperty?: string;
  // Used for lookup type
  filterValues?: IFormDropdownOption[];

  // used for progress type, this is a name of field for total number
  progress?: string;

  // Min width of the column. Default values are set according to the types
  width?: number;

  disabled?: boolean;

  hideCondition?: any;

  // used for card view, possible values are first and second
  position?: string;

  // used for card view
  hideLabel?: boolean;

  // used in case of avatar type to generate initial from full name
  avatarName?: string;

  // used in case of joinArray type to specify which field from array of elements will be displayed
  joinField?: string;

  // user in case of icon type to specify color of icon
  iconColor?: string;

  // used in case of icon type to specift tooltip
  iconTooltip?: string;

  // use in case of number type to avoid automatically conversion of null value to 0.00
  showNullValue?: boolean;

  // used in formats columns, if a format doesn't have mandatory questions, and format completeness is MandatoryQuestions, display format as DONE
  formatCompleteness?: number;

  hideColumn?: boolean;

  method?: any;

  disableSorting?: boolean;

  currency?: string;
}

interface ColorRange {
  min: number;
  max: number;
  color: string;
}

export interface ITableAction {
  // angular mat-icon name
  icon: string;

  // Tooltip in case of inline menu or title in case of normal menu
  tooltip: string;

  // Method that should be called when user clicks on an icon
  method: any;

  getBadgeNumber?: any;

  // Method is called when we build menu items
  // Method should return true or false, according to the row data
  // If it is not specified then default method will be called. Default method returns true by default
  conditionMethod?: any;

  visibleConditionMethod?: any;

  // Permission string value to check the permission from the permissions service
  permission?: string;
  // Operation to be used when sending multiple permissions
  permissionOperator?: 'and' | 'or';
  // Calculated value from the service
  hasPermission?: boolean;

  // whether to pass the row item to permission calculations
  includeEntityForPermissionCalculation?: any;

  // in certain situations the backend calculates the access
  // if we are escalated - this should be skipped
  backendAccessCheck?: any;
  // the method being called on the template
  entityPermissionMethod?: any;

  // Color
  iconColor?: string;

  includeBadgeNumber?: boolean;
}

export interface ITableOption {
  // Name of the table, showed in upper left part.
  name?: string;

  // Show total count
  showTotalCount?: boolean;
  totalCount?: number;

  // Observable with JSON data to populate table. This one should be used if the data is not static
  // (e.g. waiting for service to retrieve the data from the backend).
  dataObservable?: Observable<any>;

  //Static JSON data used to populate the table.
  // Only one data can be use, this one or dataObservable.
  // If this one is defined dataObservable will be ignored
  data?: object[];

  // See ITableColumn interface
  columns: ITableColumn[];

  // See ITableAction interface
  inlineActions?: ITableAction[];

  // See ITableAction interface
  menuActions?: ITableAction[];

  // In case we are missing avatar image, this value from JSON data will be used to generate initials
  avatarNameColumn?: string;

  // Indicator to show or hide header row in a table
  showHeader?: boolean;

  // Indicator to show or hide add button
  showAdd?: boolean;

  // observable indicator to show or hide add button
  showAdd$?: Subject<boolean> | Observable<boolean>;

  // Indicator to show or hide load existing button
  showLoadExisting?: boolean;

  // observable indicator to show or hide load existing button
  showLoadExisting$?: Subject<boolean> | Observable<boolean>;
  showProcess?: boolean;

  // Indicator to show or hide search field
  showSearch?: boolean;
  showSearch$?: Subject<boolean> | Observable<boolean>;

  // Indicator to show or hide pagination
  showPagination?: boolean;

  // Default page size for pagination
  defaultPageSize: number;

  // If this is true, sorting will be done on client side
  internalSort?: boolean;

  // If we are using internal sort this is default sort column
  defaultSortColumn?: string;

  // If we are using internal sort this is default sort direction
  defaultSortDirection?: string;

  externPagination?: boolean;

  // Show or hide fiter per column
  showFilter?: boolean;

  titleIcon?: string;

  selectionResult$?: BehaviorSubject<any>;

  selectionTwoResult$?: BehaviorSubject<any>;

  selectionRowResult$?: BehaviorSubject<any>;

  // Allow multiple selection
  hasMultipleSelection?: boolean;

  // If selecting one autopopulate the other one
  isSelectionDependant?: boolean;

  showExport?: boolean;

  showTitle?: boolean;
  colorIndicatorRange?: ColorRange[];
}

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  providers: [TableViewInterface, ExcelService, TitleCasePipe],
})
export class TableViewComponent implements OnInit, OnDestroy, OnChanges {
  // See ITableOption interface
  @Input() options: ITableOption = null;
  @Input() tableMenu: ITableAction[];
  @Input() loading: boolean;
  @Input() hideMenuAction: boolean = false;
  @Input() confirmationBar: boolean = false;
  @Input() selectAllOption: boolean = false;
  @Input() confirmationTitle: string = '';
  @Input() TOOLBAR_ICON_AMOUNT = 3;
  @Input()
  public highlightedNonSelected: boolean[] = [];
  @Input() emptyCheck: boolean = false;
  @Input() checkData: boolean = false;
  @Input() showSelectAll: boolean = false;
  // This event is triggered when user clicks on row header or change search field.
  // Event will emit the data with following information:
  // pageIndex, pageSize, sortColumn, sortDirection and search
  @Output() refreshRequest = new EventEmitter<PaginationDataModel>();

  // This event is triggered when user clicks on Add button
  @Output() addRequest = new EventEmitter<any>();
  // This event is triggered when user clicks on Load existing button
  @Output() loadExistingRequest = new EventEmitter<any>();
  @Output() processRequest = new EventEmitter<any>();
  @Output() confirmationRequest = new EventEmitter<any>();
  @Output() selectAllRequest = new EventEmitter<any>();

  // This event is triggered when user clicks on Make all hours final
  @Output() makeAllHoursFinalRequest = new EventEmitter<any>();

  // This event is triggered when user clicks on table row with data.
  // Event will emit the selected row data
  @Output() onRowClick = new EventEmitter<any>();
  @Output() onObjectListItemClick = new EventEmitter<any>();

  @Output() interface = new EventEmitter<TableViewInterface>();
  @Output() doubleClickHandler = new EventEmitter<any>();
  @Output() checkboxChanged = new EventEmitter<any>();
  @Output() allCheck = new EventEmitter<any>();
  @Output() toggleSingleSelect = new EventEmitter<any>();
  @Output() allToggleCheck = new EventEmitter<any>();
  @Output() numberInputChanged = new EventEmitter<any>();
  @Output() dateInputChanged = new EventEmitter<any>();

  @Input() showSearch = true;

  searchControl = new FormControl();
  headerCheckbox = false;

  get menuActions(): ITableAction[] {
    return this.tableMenu
      ? this.tableMenu
      : this.options?.menuActions
      ? this.options.menuActions
      : [];
  }

  public data: any[];

  public displayedColumns: string[];
  public totalRows = 0;
  @Input()
  public pageSize = 10;
  public pageIndex = 0;
  public tmpPageSize = 10;
  public tmpPageIndex = 0;
  public sortColumn = '';
  public sortDirection = '';
  public search = '';
  @Input()
  public pageSizeOptions = [5, 10, 20, 50];
  public minWidth = 0;
  public showFilter: boolean = false;

  public additionalFilters: any = {};

  public selection = new SelectionModel<any>(false, []);
  public selectionTwo = new SelectionModel<any>(false, []);
  public selectionRow = new SelectionModel<any>(false, []);
  public highlighted: boolean[] = [];

  public columnWidths = {
    default: 180,
    date: 100,
    string: 180,
    lookup: 180,
    number: 80,
    status: 80,
    boolean: 80,
    checkbox: 80,
    avatar: 40,
    progress: 40,
    objectList: 180,
    function: 180,
    joinArray: 180,
  };

  private readonly destroyed$ = new Subject<boolean>();
  private isExport: boolean = false;
  public DAY_OF_YEAR_PATTERN = REGEX_CONSTANTS.DAY_OF_YEAR;
  public minDate: Date;
  onKeydown = DATE_REGEX;

  formGroup: FormGroup = null!;

  constructor(
    private permissionsService: PermissionService,
    private tableViewInterface: TableViewInterface,
    private cdref: ChangeDetectorRef,
    private excelService: ExcelService,
    private translate: TranslateService,
    private titleCasePipe: TitleCasePipe,
    private fb: FormBuilder,
  ) {}
  public defaultConditionMethod(row: any): boolean {
    return true;
  }

  get dateInputControl() {
    return this.formGroup.get('dateInput');
  }

  buildInputForm() {
    return this.fb.group({
      dateInput: [null],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.options?.currentValue?.data) {
      if (changes.options.currentValue?.totalCount >= 0)
        this.totalRows = changes.options.currentValue?.totalCount;
      this.data = changes.options.currentValue.data;
      if (changes.options.currentValue?.selectionResult$?.value?.id) {
        this.selection.select(
          changes.options.currentValue?.selectionResult$?.value?.id,
        );
      }
    }
    const menuActions = changes.tableMenu?.currentValue;
    if (menuActions) {
      this.initMenuItems();
    }
  }

  ngOnInit() {
    this.formGroup = this.buildInputForm();
    registerLocaleData(nl);
    if (this.options.showAdd$)
      this.options.showAdd$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((showAdd) => (this.options.showAdd = showAdd));

    if (this.options.showLoadExisting$)
      this.options.showLoadExisting$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (showLoadExisting) =>
            (this.options.showLoadExisting = showLoadExisting),
        );

    if (this.options.showSearch$)
      this.options.showSearch$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((showSearch) => (this.options.showSearch = showSearch));

    this.initColumns();
    this.setDefaultValues();

    if (this.options.data) {
      this.data = this.options.data;
      if (this.options?.totalCount) this.totalRows = this.options.totalCount;
      this.initMenuItems();
    } else if (this.options.dataObservable) {
      this.options.dataObservable
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value: any) => {
          if (value) {
            if (value.data && value.data.length > 0) {
              this.initMenuItems();
            } else {
              if (value.length > 0) {
                this.initMenuItems();
              }
            }
          }

          if (value && !this.isExport) {
            // maybe calculate all permissions here so the conditionMethod doesn't get called all the time
            if (this.options.externPagination) {
              this.data = [...value.data];
              this.totalRows = value.count;
            } else {
              this.data = [...value];
              this.totalRows = value.length;
            }
            if (this.options.internalSort) {
              this.internalSort();
            }
          } else if (value && this.isExport) {
            this.excelService.exportAsExcelFile(value.data, this.options.name);
            this.isExport = false;
            this.pageSize = this.tmpPageSize;
            this.pageIndex = this.tmpPageIndex;
            this.refresh();
          }
          this.cdref.detectChanges();
        });
    }
    this.initSelection();
    this.initSearch();
    this.initInterface();
    this.interface.emit(this.tableViewInterface);
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0);
    this.minDate = date;    
  }

  onNumberInputChanged(event: any, element: any, columnId: string) {
    this.numberInputChanged.emit({
      value: event?.target?.value,
      element,
      columnId,
    });
  }

  onDateInputEvent(event: any, element: any) {
    const inputDate = event.value;
    if (!inputDate) {
      this.dateInputChanged.emit({ inputDate: null, element: element });
      return;
    }
    const inputMidnight = new Date(inputDate);
    inputMidnight.setHours(0, 0, 0, 0);
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    if (inputMidnight >= today) {
      this.dateInputChanged.emit({ inputDate: inputDate, element: element });
    } else {
      event.targetElement.value = null;
      this.dateInputChanged.emit({ inputDate: null, element: element });
    }
  }

  onCheckboxChange(event: any, element: any, columnId: string): void {
    this.checkboxChanged.emit({ event, element, columnId });
  }

  toggleAll(checked: boolean) {
    this.data.forEach((row) => {
      row[
        this.options.columns.find((column) => column.type === 'toggleButton').id
      ] = checked;
    });
    this.allToggleCheck.emit(checked);
  }
  toggleSingle(event: any, element: any, columnId: string): void {
    this.toggleSingleSelect.emit({ event, element });
  }

  allCheckRequest(checked: boolean, columnId: any) {
    this.data.forEach((row) => {
      row[this.options.columns.find((column) => column.id === columnId).id] =
        checked;
    });
    this.allCheck.emit({ checked, columnId });
  }
  selectAllHeaderCheckbox(checked: boolean) {
    this.headerCheckbox = checked;
  }

  public getTranslatedStatus(element, column) {
    const statusKey = this.titleCasePipe.transform(element[column?.id]);
    return this.translate.instant(`general.status.${statusKey}`);
  }

  private initColumns(): void {
    this.minWidth = 150;
    this.options.columns = this.options.columns.filter(
      (item) => !item.hideColumn,
    );
    this.displayedColumns = this.options.columns.map((a) => a.id);

    for (let column of this.options.columns) {
      if (!column.width)
        column.width = this.columnWidths[column.type]
          ? this.columnWidths[column.type]
          : this.columnWidths['default'];
      this.minWidth += column.width;
    }
  }

  setDefaultValues() {
    if (this.options.defaultPageSize)
      this.pageSize = this.options.defaultPageSize;
    if (this.options.defaultSortColumn)
      this.sortColumn = this.options.defaultSortColumn;
    if (this.options.defaultSortDirection)
      this.sortDirection = this.options.defaultSortDirection;
  }

  isMenuVisible(element, rowIndex): boolean {
    if (!this.hideMenuAction) return true;
    return this.menuActions.some(
      (actions) =>
        actions.visibleConditionMethod &&
        actions.visibleConditionMethod(element),
    );
  }

  progressBar(column: any, element: any) {
    return (
      column.formatCompleteness &&
      column.formatCompleteness === 2 &&
      element[column.progress] === 0
    );
  }

  private initInterface(): void {
    this.tableViewInterface.search$
      .pipe(
        debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.searchControl.patchValue(val);
      });
    this.tableViewInterface.showSearch$
      .pipe(
        debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.showSearch = val;
        this.cdref.detectChanges();
      });
    this.tableViewInterface.pageIndex$
      .pipe(
        debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.pageIndex = val;
        this.cdref.detectChanges();
        this.refresh();
      });
    this.tableViewInterface.pageSizeIndex$
      .pipe(
        debounceTime(DEFAULT_CONTROL_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe((val) => {
        this.pageSize = val.pageSize;
        this.pageIndex = val.pageIndex;
        // this.cdref.detectChanges();
        // this.refresh();
      });
  }

  onEvent(event) {
    event.stopPropagation();
  }

  onFilterChange(id: string, value) {
    this.refresh();
  }

  private refresh(searchControlChanged?: boolean) {
    if (searchControlChanged) this.pageIndex = 0;
    if (!this.sortDirection || !this.sortColumn) {
      this.refreshRequest.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sortColumn: this.options.defaultSortColumn,
        sortDirection: this.options.defaultSortDirection,
        search: this.searchControl.value,
      });
    } else {
      this.refreshRequest.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        search: this.searchControl.value,
      });
    }
  }

  public onExport(): void {
    this.tmpPageSize = this.pageSize;
    this.tmpPageIndex = this.pageIndex;
    this.pageSize = 999;
    this.pageIndex = 0;
    this.isExport = true;
    this.refresh();
  }

  public handlePage(e: any) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.refresh();
  }

  private internalSort(): void {
    if (this.data) {
      const data = this.data.slice();
      if (!this.sortColumn || this.sortDirection === '') {
        this.sortColumn = this.options.defaultSortColumn;
        this.sortDirection = this.options.defaultSortDirection;
      }

      this.data = data.sort((a, b) => {
        const isAsc = this.sortDirection === 'asc';
        return compare(a[this.sortColumn], b[this.sortColumn], isAsc);
      });
    }
  }

  public handleSort(e: any): void {
    this.sortColumn = e.active;
    this.sortDirection = e.direction;

    if (this.options.internalSort) {
      this.internalSort();
    } else if (!this.sortDirection || !this.sortColumn) {
      this.refreshRequest.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sortColumn: this.options.defaultSortColumn,
        sortDirection: this.options.defaultSortDirection,
        search: this.searchControl.value,
      });
    } else {
      this.refresh();
    }
  }

  public onAdd(): void {
    this.addRequest.emit();
  }
  public onLoadExisting(): void {
    this.loadExistingRequest.emit();
  }

  public onProcessGroups(): void {
    this.processRequest.emit();
  }

  public onSelectAll(checked): void {
    this.selectAllRequest.emit(checked);
  }

  public onConfirm(): void {
    this.confirmationRequest.emit(true);
  }
  public onCancel(): void {
    this.confirmationRequest.emit(false);
  }

  public onMakeAllHoursFinal(): void {
    this.makeAllHoursFinalRequest.emit();
  }

  public onClick(row: any, i: number): void {
    this.highlighted = [];
    this.highlighted[i] = true;
    this.onRowClick.emit(row);
  }

  onDoubleClick(row: any, i: number): void {
    this.doubleClickHandler.emit(row);
    this.menuActions;

    const edit = this.menuActions.find((action) => action.icon === 'create');
    const view = this.menuActions.find(
      (action) => action.icon === 'visibility',
    );

    if (
      (!edit?.includeEntityForPermissionCalculation &&
        edit?.hasPermission &&
        edit.conditionMethod(row)) ||
      (edit?.includeEntityForPermissionCalculation &&
        edit?.entityPermissionMethod(row))
    ) {
      return edit.method(row);
    }
    if (
      (!view?.includeEntityForPermissionCalculation &&
        view?.hasPermission &&
        view.conditionMethod(row)) ||
      (view?.includeEntityForPermissionCalculation &&
        view?.entityPermissionMethod(row))
    ) {
      return view.method(row);
    }
  }

  isSelectedRow(id) {
    return this.selectionRow.selected.some((selection) => selection.id === id);
  }

  private initSelection() {
    this.selectionTwo = this.options?.hasMultipleSelection
      ? new SelectionModel<any>(true, [])
      : new SelectionModel<any>(false, []);
    this.selectionRow = this.options?.hasMultipleSelection
      ? new SelectionModel<any>(true, [])
      : new SelectionModel<any>(false, []);

    if (this.options?.selectionResult$) {
      if (
        this.options.selectionResult$.value !== null &&
        this.options.selectionResult$.value !== undefined
      ) {
        this.selection.select(this.options.selectionResult$.value.id);
        this.options.selectionResult$.next(
          this.options.selectionResult$.value.id,
        );
      }
      this.selection.changed
        .pipe(
          takeUntil(this.destroyed$),
          map((selection) => {
            if (selection.added) {
              return selection.added[0];
            } else {
              return null;
            }
          }),
        )
        .subscribe(this.options.selectionResult$);
    }

    if (this.options?.selectionRowResult$) {
      this.selectionRow.changed
        .pipe(
          takeUntil(this.destroyed$),
          map((selection) => {
            if (selection.added.length) {
              if (this.options?.isSelectionDependant) {
                // Auto-populate second selection
                this.selectionTwo.select(selection.added[0].id);
                return { type: 'added', id: selection.added[0].id };
              }
              return selection.added[0];
            } else {
              if (this.options?.isSelectionDependant) {
                return { type: 'removed', id: selection.removed[0].id };
              }
              return null;
            }
          }),
        )
        .subscribe(this.options.selectionRowResult$);
    }

    if (this.options?.selectionTwoResult$) {
      this.selectionTwo.changed
        .pipe(
          takeUntil(this.destroyed$),
          map((selection) => {
            if (selection.added.length) {
              return { type: 'added', id: selection.added[0] };
            } else {
              return { type: 'removed', id: selection.removed[0] };
            }
          }),
        )
        .subscribe(this.options.selectionTwoResult$);
    }
  }

  private initSearch() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.refresh(true));
  }

  private initMenuItems() {
    if (this.menuActions && this.displayedColumns) {
      this.displayedColumns = this.displayedColumns.filter(
        (column) => column !== 'menu_action' && column !== 'inline_action',
      );
      this.displayedColumns.push('inline_action');

      if (this.TOOLBAR_ICON_AMOUNT > this.menuActions.length)
        this.TOOLBAR_ICON_AMOUNT = this.menuActions.length;
      if (this.menuActions.length > this.TOOLBAR_ICON_AMOUNT) {
        this.displayedColumns.push('menu_action');
      }
      for (let action of this.menuActions) {
        // if an entity is passed we skip all other calculations
        // horrible solution which calls  the permission service multiple times
        if (action.includeEntityForPermissionCalculation) {
          // populate unexisting methods
          if (!action.conditionMethod)
            action.conditionMethod = this.defaultConditionMethod.bind(this);
          if (!action.backendAccessCheck)
            action.backendAccessCheck = this.defaultConditionMethod.bind(this);

          action.entityPermissionMethod = (entity: any) => {
            {
              const { escalated, hasPermission } =
                this.permissionsService.getEntityPermission({
                  permission: action.permission,
                  operation: action.permissionOperator,
                  entity,
                });
              if (escalated) {
                // business logic is still valid in this case
                // i.e. entity.status === 'closed' we still can't access

                // this will solve double click for now, business rules for condition should be checked
                action.hasPermission = action.conditionMethod(entity);

                return action.conditionMethod(entity);
              } else {
                // if there is no escalations, all of the checks need to pass

                // this will solve double click for now, business rules for condition should be checked
                action.hasPermission =
                  hasPermission &&
                  action.backendAccessCheck(entity) &&
                  action.conditionMethod(entity);

                return (
                  hasPermission &&
                  action.backendAccessCheck(entity) &&
                  action.conditionMethod(entity)
                );
              }
            }
          };
          continue;
        }

        if (action.permission) {
          action.hasPermission = this.permissionsService.getPermission(
            action.permission,
            action.permissionOperator,
          );
        } else {
          action.hasPermission = true;
        }
        if (!action.conditionMethod)
          action.conditionMethod = this.defaultConditionMethod.bind(this);
      }
    }
  }

  public hideAllButtons() {
    // we get an ExpressionChangedAfterItHasBeenCheckedError if there is no timeout
    setTimeout(() => {
      this.options.showAdd = false;
      this.options.showSearch = false;
      this.options.showExport = false;
    });
  }

  public hideAddAndSearch() {
    // we get an ExpressionChangedAfterItHasBeenCheckedError if there is no timeout
    setTimeout(() => {
      this.options.showAdd = false;
      this.options.showSearch = false;
    });
  }

  public hideAdd() {
    // we get an ExpressionChangedAfterItHasBeenCheckedError if there is no timeout
    setTimeout(() => {
      this.options.showAdd = false;
    });
  }

  public hideSearch() {
    // we get an ExpressionChangedAfterItHasBeenCheckedError if there is no timeout
    setTimeout(() => {
      this.options.showSearch = false;
    });
  }

  public hideMenuActions() {
    // we get an ExpressionChangedAfterItHasBeenCheckedError if there is no timeout
    setTimeout(() => {
      this.options.menuActions = [];
    });
  }

  public addColumn(columnOption: ITableColumn, atBeginning?: boolean) {
    // we get an ExpressionChangedAfterItHasBeenCheckedError if there is no timeout
    setTimeout(() => {
      if (atBeginning) {
        this.options.columns.unshift(columnOption);
      } else this.options.columns.push(columnOption);
      this.initColumns();

      this.initMenuItems();
      // not sure about refresh after adding column
      // this.refresh();
    });
  }

  public removeColumn(columnId: string) {
    // we get an ExpressionChangedAfterItHasBeenCheckedError if there is no timeout
    setTimeout(() => {
      this.options.columns = this.options.columns.filter(
        (c) => c.id !== columnId,
      );
      this.initColumns();
      this.initMenuItems();
    });
  }

  getTooltip(element: any, column: any): string {
    return element[column?.id] === HOUR_LINK_FORMAT.HOUR_LINK_REPORT_ICON
      ? HOUR_LINK_FORMAT.HOUR_LINK_REPORT_ICON_TEXT
      : HOUR_LINK_FORMAT.HOUR_LINK_CONTACT_LOGBOOK_ICON_TEXT;
  }

  getIcon(element: any, column: any): string {
    return element[column?.id] === HOUR_LINK_FORMAT.HOUR_LINK_REPORT_ICON
      ? HOUR_LINK_FORMAT.HOUR_LINK_REPORT_ICON
      : HOUR_LINK_FORMAT.HOUR_LINK_CONTACT_LOGBOOK_ICON;
  }

  public checkIfColumnExists(columnId: string) {
    return this.options.columns.find((c) => c.id === columnId);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  selectObjectListItem(element) {
    this.onObjectListItemClick.emit(element);
  }
  getBackgroundColor(value) {
    let bgColor = 'none';
    this.options.colorIndicatorRange.forEach((range) => {
      if (value >= range.min && value < range.max) {
        bgColor = range.color;
        return;
      }
    });
    return bgColor;
  }

  public readonly SELECT_ALL_KEY = SELECT_ALL_KEY;
}

// function is used for internal sorting
// undefined values are always greater then other values
function compare(
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean,
) {
  if (a == undefined) return 1 * (isAsc ? 1 : -1);
  else if (b == undefined) return -1 * (isAsc ? 1 : -1);
  else return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
